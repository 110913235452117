import { PageProps } from "gatsby"
import React from "react"
import { useIntl } from "react-intl"

import { Seo } from "../components/Seo/Seo"

import { SelectLanguageModal } from "../components/SelectLanguageModal/SelectLanguageModal"
import { Navbar } from "../components/Navbar/Navbar"
import { BackgroundShapes } from "../components/BackgroundShapes"
import { Drawer } from "../components/Drawer/Drawer"
import { Achievements } from "../components/Achievements/Achievements"
import { GlobalWrapper } from "../components/GlobalWrapper/GlobalWrapper"
import { Footer } from "../components/Footer/Footer"
import { MobileNavbar } from "../components/MobileNavbar/MobileNavbar"

type Props = PageProps<
  {},
  {
    langKey: string
    canonicals: Array<{
      lang: string
      url: string
      isDefault: boolean
      label: string
    }>
  }
>

const StatsPage: React.FC<Props> = (props) => {
  const intl = useIntl()

  return (
    <GlobalWrapper>
      <Seo
        title={intl.formatMessage({
          id: `achievements/title`,
        })}
        description={intl.formatMessage({
          id: `achievements/description`,
        })}
        index={false}
        lang={props.pageContext.langKey}
        langUrls={props.pageContext.canonicals}
      />

      <SelectLanguageModal languages={props.pageContext.canonicals} />

      <div className="relative overflow-hidden bg-white">
        <BackgroundShapes step={0} absolute />

        <Navbar langKey={props.pageContext.langKey} />

        <Achievements />
        <MobileNavbar langKey={props.pageContext.langKey} />
      </div>

      <Footer lang={props.pageContext.langKey} />
      <Drawer langKey={props.pageContext.langKey} />
    </GlobalWrapper>
  )
}

export default StatsPage
