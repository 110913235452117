import React from "react"
import { AchievementWithRewardEntity } from "../../entities/AchievementEntity"
import { ITranslations } from "../../interfaces/ITranslations"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { PageHeading } from "../PageHeading/PageHeading"
import { connector, ContainerProps } from "./containers/Achievements.container"

export const Wrapper: React.FC<{
  achievements: AchievementWithRewardEntity[]
}> = (props) => {
  return (
    <>
      <PageHeading
        title={<FormattedMessage id="achievements/title" />}
        description={<FormattedMessage id="achievements/description" />}
      />

      <div className="relative px-2">
        <div className="grid w-full max-w-3xl mx-auto">
          {props.achievements.map((achievement, index) => {
            const achieved = achievement.value >= achievement.goal
            const percentage = Math.round(
              (achievement.value / achievement.goal) * 100
            )

            return (
              <div
                key={achievement.id}
                className="relative flex items-center w-full p-4 py-6 mt-4 overflow-hidden bg-white rounded border border-slate-200"
              >
                <div className="relative flex-shrink-0">
                  <img
                    className="w-16 h-16"
                    src={`/avatars/${achievement.reward}`}
                  />
                </div>
                <div className="relative w-full ml-4">
                  <div className="flex justify-between text-slate-900 font-display">
                    <FormattedMessage
                      id={achievement.id as ITranslations["keys"]}
                    />
                    <span>{percentage > 100 ? 100 : percentage}%</span>
                  </div>

                  <div
                    className={` ${
                      achieved ? "text-green-500" : "text-slate-500"
                    }`}
                  >
                    {!achieved && (
                      <FormattedMessage
                        id={
                          (achievement.parent +
                            "/status") as ITranslations["keys"]
                        }
                        values={{ v: `${achievement.value}` }}
                      />
                    )}
                    {achieved && (
                      <FormattedMessage id="stats.achievements.finish" />
                    )}
                  </div>

                  <div className="w-full h-2 mt-2 overflow-hidden rounded-full bg-slate-100">
                    <div
                      style={{
                        width:
                          (achievement.value / achievement.goal) * 100 + "%",
                      }}
                      className={`h-full ${
                        achieved ? "bg-green-500" : "bg-blue-100"
                      } rounded-full`}
                    ></div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const Achievements = connector(Container)
